
import { mapState } from "vuex";

export default {
  name: "Style",
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
  }

}

