
import axios from 'axios';
import CardSkeleton from "@/components/global/skeletons/CardSkeleton.vue";

export default {
  data() {
    return {
      placesReviews: [],
      isLoading: true
    };
  },
  components: {
    CardSkeleton
  },
  computed: {
    placeIds() {
      return this.networkConfig?.greview?.placeIds || [];
    },
    apiKey() {
      return this.networkConfig?.greview?.apiKey || '';
    },
    currentLanguage() {
      return this.$i18n.locale; // Ottieni la lingua attuale dall'i18n
    }
  },
  methods: {
    async getReviews(placeId) {
      try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/place/details/json`, {
          params: {
            placeid: placeId,
            key: this.apiKey,
            language: this.currentLanguage
          }
        });
        const result = response.data.result;
        return {
          place_id: placeId,
          name: result.name,
          reviews: result.reviews || []
        };
      } catch (error) {
        console.error('Errore nel recuperare le recensioni per', placeId, ':', error);
        return {
          place_id: placeId,
          name: 'Unknown Place',
          reviews: []
        };
      }
    },
    async fetchAllReviews() {
      if (this.placeIds.length && this.apiKey) {
        const reviewsPromises = this.placeIds.map(this.getReviews);
        this.placesReviews = await Promise.all(reviewsPromises);
      }
      this.isLoading = false;
    }
  },
  mounted() {
    if (this.networkConfig && this.networkConfig.greview) {
      this.fetchAllReviews();
    }
  },
  watch: {
    'networkConfig': {
      handler() {
        if (this.networkConfig && this.networkConfig.greview) {
          this.fetchAllReviews();
        }
      },
      deep: true,
      immediate: true
    },
    '$i18n.locale': {
      handler() {
        if (this.networkConfig && this.networkConfig.greview) {
          this.isLoading = true;
          this.fetchAllReviews();
        }
      },
      immediate: true
    }
  }
};
