
  import { mapState } from 'vuex';

  export default {
    name: "Blocks",
    props: {
      hook: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        showHookName: false
      }
    },
    created() {
      if (this.$route.query.dev === 'true') {
        this.showHookName = true;
      }
    },
    computed: {
      ...mapState({
        networkBlocks: state => state['booking-networks'].getBookingNetworkBlocksRequest.data,
      }),
      blocks() {
        if (!this.networkBlocks.length) {
          return [];
        }

        return this.networkBlocks.filter(block => !!block.hook && block.hook.indexOf(this.hook) > -1);
      },
    }
  }
