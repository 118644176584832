
  import search from "@/mixins/search";

  export default {
    name: "SearchBlock",
    props: {
      fixed: {
        type: Boolean,
        default: true,
      },
    },
    mixins: [
      search
    ],
    data() {
      return {
        isOpenFilterModal: false,
      };
    },
    methods: {
      openFilterModal() {
        this.isOpenFilterModal = !this.isOpenFilterModal;
      }
    },
    computed: {
      returnClass() {
      let classes = [];

      // Puoi aggiungere le tue condizioni qui
      if (this.$nuxt.$route.path.includes('properties/')) {
        classes.push('searchblock-in-property');
      }

      if (this.isSimpleView) {
        classes.push('searchblock-in-simpleview')
      }

      if (!this.isSimpleView) {
        classes.push('searchblock-in-completeview')
      }

      return classes;
    },
      startDateFormatted() {
        if (!this.searchFilters.start_date) {
          return this.$t('main.words.checkin');
        }

        return this.$moment(this.searchFilters.start_date).format('D MMM');
      },
      endDateFormatted() {
        if (!this.searchFilters.end_date) {
          return this.$t('main.words.checkout');
        }

        return this.$moment(this.searchFilters.end_date).format('D MMM')
      }
    },
    mounted() {
    },
  };
