import { render, staticRenderFns } from "./VHeader.vue?vue&type=template&id=3d55b9a6"
import script from "./VHeader.vue?vue&type=script&lang=js"
export * from "./VHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBlock: require('/home/cb3-be/components/global/SearchBlock.vue').default,Blocks: require('/home/cb3-be/components/global/Blocks.vue').default,TopNavBar: require('/home/cb3-be/components/global/TopNavBar.vue').default})
