
  import locale from '@/mixins/locale';

  export default {
    name: "TopNavBar",
    mixins: [
      locale
    ],
    data() {
      return {
        navHeight: null
      };
    },
    head() {
      return {
        bodyAttrs: {
          style: `--navbar-height: -${this.navHeight}px; --navbar-space: ${this.navHeight}px; `
        },
      }
    },
    props: {
      fixed: {
        type: Boolean,
        default: true,
      },
      transparent: {
        type: Boolean,
        default: false,
      }
    },
    mounted() {
      this.setnavHeight();
      window.addEventListener("resize", this.setnavHeight);
    },
    methods: {
      isEnabledDynamicPage(pageKey) {
        return !!this.network.dynamic_pages &&
          this.network.dynamic_pages.length > 0 &&
          this.network.dynamic_pages.indexOf(pageKey) > -1;
      },
      setnavHeight() {
        if(!!this.$refs.navbarMain) {
          this.navHeight = this.$refs.navbarMain.$el.scrollHeight;
        }
      },
    },
    computed: {
      availableLocales() {
        return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
      },
      propertyId() {
        if (this.$route.name === `properties-id___${this.$i18n.locale}`)
        
        return this.$route.params.id;
      }
    },
  };
