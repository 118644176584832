
  import { mapState } from "vuex";
  import search from "@/mixins/search";

  export default {
    name: "SearchFilterModal",
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    mixins: [
      search
    ],
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      close() {
        this.$emit("close");
      },
      async action() {
        this.close();

        if (this.$route.name === `properties-id___${this.$i18n.locale}`) {
          await this.submitSearch({
            id: this.$route.params.id,
            single: true,
            combinations: true,
            redirect: 'single'
          });
        } else {
          await this.submitSearch({
            data: true,
            redirect: 'properties'
          });
        }
      }
    },
    watch: {
      show(newVal) {
        this.isOpen = newVal;
      },
    },
	  computed: {
      ...mapState({
        network: state => state['booking-networks'].getBookingNetworksRequest.data,
      }),
      isShowChildren() {
        return !!this.network.is_search_show_children;
      },
      isShowRooms() {
        return !!this.network.is_search_show_rooms;
      },
    }
  };
