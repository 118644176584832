
import { mapState } from "vuex";

export default {	
	name: "VFooter",
	computed: {
		...mapState({
			network: state => state['booking-networks'].getBookingNetworksRequest.data,
		}),
		vat() {
			return this.network.company.vat_code;
		}
	}
}
